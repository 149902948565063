<!-- 充值步骤 -->
<template>
  <div class="rechargestep">
    <div class="box" :class='["steps","steps1","steps2"][steps]'>
      <div class="stepson"><span>1</span><p>1.填写充值金额</p></div>
      <div class="stepson"><span>2</span><p>2.在线支付</p></div>
      <div class="stepson"><span>3</span><p>3.充值完成</p></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'rechargestep',
  props: {
    steps: Number
  },
  methods:{
  }
}
</script>
<style  lang="scss" scoped>
.rechargestep {
  .box {
    display:flex;align-items: center;justify-content: center;width:480px;position: relative;height: 60px;
    .stepson {
      width: 160px;height: 50px;position: relative;
      span {
        display: block;width: 20px;height: 20px;text-align: center;line-height: 20px;margin:0 auto;font-size: 12px;position: relative;z-index: 9;margin-top:5px;background: #c1c1c1;color:#fff;border-radius: 50%;line-height: 20px;
      }
      p {
        text-align: center;font-size: 12px;margin-top:5px;
      }
      &:after {
        content: '';display: block;position: absolute;width: 160px;height: 2px;background: #c1c1c1;top:15px;left:0px;z-index: 8;
      }
      &:nth-child(1) {
        // span {
        //   background: #da5777;color:#fff;border-radius: 50%;line-height: 20px;
        // }
      }
      &:nth-child(2) {
        // span {
        //   background: #da5777;color:#fff;border-radius: 50%;line-height: 20px;
        // }
      }
      &:nth-child(3) {
        // span {
        //   background: #c1c1c1;color:#fff;border-radius: 50%;line-height: 20px;
        // }
        // &:after {
        //   background: #c1c1c1;
        // }
      }
    }
    &:after {
      content: '';position: absolute;width: 480px;height: 2px;background: #c1c1c1;top:20px;left:0px;
    }
  }
  .steps {
    .stepson {
      &:nth-child(1) {
        span {
          background: #da5777;
        }
        &:after {
          background: #da5777;
        }
      }
    }
  }
  .steps1 {
    .stepson {
      &:nth-child(1) {
        span {
          background: #da5777;
        }
        &:after {
          background: #da5777;
        }
      }
      &:nth-child(2) {
        span {
          background: #da5777;
        }
        &:after {
          background: #da5777;
        }
      }
    }
  }
  .steps2 {
    .stepson {
      &:nth-child(1) {
        span {
          background: #da5777;
        }
        &:after {
          background: #da5777;
        }
      }
      &:nth-child(2) {
        span {
          background: #da5777;
        }
        &:after {
          background: #da5777;
        }
      }
      &:nth-child(3) {
        span {
          background: #da5777;
        }
        &:after {
          background: #da5777;
        }
      }
    }
  }
}
</style>
