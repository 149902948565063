<!-- 充值-支付页面 -->
<template>
  <div class="rechargemethod">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='充值'></logotitle>
      </el-col>
      <el-col :span="12">
        <rechargestep class='rechargestep' :steps='1'></rechargestep>
      </el-col>
    </el-row>
    <div class="infos">
      <div class="lt">
        <h6>订单提交成功，请尽快付款！<p>该订单<span>24</span>小时内未支付将自动取消</p></h6>
        <h6>订单号：{{this._spf(this.$route.query.orderNumber)}}</h6>
      </div>
      <div class="rt">
        充值金额：<p>{{this._spf(this.$route.query.money)}}<span>元</span></p>
      </div>
    </div>
    <paymethodre :productData='productData' class='paymethodre'></paymethodre>
  </div>
</template>
<script>
import rechargestep from '@/components/rechargestep/rechargestep.vue';//充值步骤
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import paymethodre from '@/components/paymethodre/paymethodre.vue';//支付方式
export default {
  name: 'rechargemethod',
  props: {
    msg: String
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'充值-微微定',
      // price:null,
      // t:0,
      productData:{
        data5:this._spf(this.$route.query.money)
      }
    }
  },
  components: {
    rechargestep,
    logotitle,
    paymethodre
  },
  methods:{
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
    // 'price'(n,o){
    //   var regEx = /^[0-9]\d*$/;
    //   if(n&&!regEx.test(n)){
    //     this.price = o
    //   }
    //   clearTimeout(this.t)
    //   this.t = setTimeout(()=>{
    //     if(n<10&&n>0){
    //       this.price =10
    //     }
    //     if(n>50000){
    //       this.price = 50000
    //     }
    //   },300)
    // }
  }
}
</script>
<style  lang="scss" scoped>
.rechargemethod {
  background: #fff;padding-bottom:100px;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;
    .rechargestep {
      float:right;margin-top:5px;
    }
  }
  .infos {
    width: 1198px;border:1px solid #ececee;background: #f9fafd;height: 68px;margin:0 auto;margin-top:30px;
    .lt {
      float:left;margin-left:25px;margin-top:13px;
      h6 {
        font-size:14px;
        p {
          display: inline-block;background: #ffeeee;border:1px solid #ffabac;border-radius: 5px;font-weight: normal;font-size: 12px;padding:1px 5px;color:#333;margin-left:10px;
          span {
            color:#e55a46;
          }
        }
      }
    }
    .rt {
      float: right;font-size: 14px;color:#333;margin-right: 25px;margin-top:20px;
      p {
        color:#e5393c;font-weight: bold;font-size: 18px;display: inline-block;
        span {
          color:#e5393c;font-weight: normal;font-size: 14px;
        }
      }
    }
  }
  .paymethodre {
    width: 1200px;margin:0 auto;margin-top:40px;
  }
}
</style>
